/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

export enum ValidationInputTypeText {
    ALPHA = 'alpha',
    ALPHASPACE = 'alphaSpace',
    ALPHANUMERIC = 'alphaNumeric',
    ALPHADASH = 'alphaDash',
    URL = 'url',
}

export enum ValidationInputTypeNumber {
    NUMERIC = 'numeric',
    NUMERICDASH = 'numericDash',
    INTEGER = 'integer',
    DECIMAL = 'decimal',
    NATURAL = 'natural',
    NATURALNOZERO = 'naturalNoZero',
}

export enum ValidationInputTypeInput {
    EMAIL = 'email',
    PASSWORD = 'password',
    DATE = 'date',
    PHONE = 'phone',
    EMAILLIST = 'emailList',
    POSTCODE = 'postcode',
    VAT = 'vat',
}

export const ValidationInputType = {
    ...ValidationInputTypeInput,
    ...ValidationInputTypeNumber,
    ...ValidationInputTypeText,
};

export const VALIDATION_MESSAGES = {
    //#region VALIDATION RULE MSG
    isRequired: __('This field is required!'),
    match: __('Incorrect input!'),
    range: __('Value is out of range!'), // Range values are also in Validator.js as they require args
    group: __('Field contains issues!'),
    fileExtension: __('Incorrect File extension upload!'),
    //#endregion

    //#region VALIDATION RULE MSG
    [ValidationInputType.ALPHA]: __('Incorrect input! Only letters allowed!'),
    [ValidationInputType.ALPHASPACE]: __('Incorrect input! Only words allowed!'),
    [ValidationInputType.ALPHANUMERIC]: __('Incorrect input! Alpha-Numeric value required!'),
    [ValidationInputType.ALPHADASH]: __('Incorrect input! Alpha-Dash value required!'),
    [ValidationInputType.URL]: __('Incorrect input! URL required!'),
    [ValidationInputType.NUMERIC]: __('Incorrect input! Numeric value required!'),
    [ValidationInputType.NUMERICDASH]: __('Incorrect input! Numeric-Dash value required!'),
    [ValidationInputType.INTEGER]: __('Incorrect input! Integer required!'),
    [ValidationInputType.NATURAL]: __('Incorrect input! Natural number required!'),
    [ValidationInputType.NATURALNOZERO]: __('Incorrect input!'),
    [ValidationInputType.EMAIL]: __('Incorrect email format!'),
    [ValidationInputType.EMAILLIST]: __('Incorrect list of emails!'),
    [ValidationInputType.DATE]: __('Incorrect date input!'),
    [ValidationInputType.PASSWORD]: __('Incorrect password input!'),
    [ValidationInputType.PHONE]: __('Incorrect phone input!'),
    [ValidationInputType.POSTCODE]: __('Incorrect postcode!'),
    [ValidationInputType.VAT]: __('Incorrect vat number!'),
    //#endregion
};

export const plRegexp = 'a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\-\\s';
export const VALIDATION_RULES = {
    // Text
    [ValidationInputType.ALPHA]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [ValidationInputType.ALPHASPACE]: new RegExp(`^[${ plRegexp }]+$`, 'i'),
    [ValidationInputType.ALPHANUMERIC]: /^[a-z0-9]+$/i,
    [ValidationInputType.ALPHADASH]: /^[a-z0-9_\\-]+$/i,
    [ValidationInputType.URL]: /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?$/,

    // Numbers
    [ValidationInputType.NUMERIC]: /^[0-9]+$/,
    [ValidationInputType.NUMERICDASH]: /^[\d\-\s]+$/,
    [ValidationInputType.INTEGER]: /^\\-?[0-9]+$/,
    [ValidationInputType.DECIMAL]: /^\d*\.?\d*$/,
    [ValidationInputType.NATURAL]: /^[0-9]+$/i,
    [ValidationInputType.NATURALNOZERO]: /^[1-9][0-9]*$/i,

    // Inputs
    // eslint-disable-next-line max-len
    [ValidationInputType.EMAIL]: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/,
    [ValidationInputType.DATE]: /\d{4}-\d{1,2}-\d{1,2}/,
    [ValidationInputType.POSTCODE]: /^\d{2}-\d{3}$/,
    [ValidationInputType.VAT]: location.host?.includes('mazovia.de') ? /^DE\d{9}$/ : /(PL)*[\d]{10}/,
    [ValidationInputType.PHONE]: /^([\\+0-9\s]{3,4})?[(]?[\d]{3}[)]?[-\s\\.]?[\d]{3}[-\s\\.]?[\d]{3,6}$/im,
    // eslint-disable-next-line max-len
    [ValidationInputType.EMAILLIST]: /^(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+([,\s]+(([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
};

export const COUNTRY_POST_CODE_REGEX_LIST_FORMAT = {
    PL: {
        countryPostCodeLength: 6,
        exampleFormat: '00-000',
        regex: /^[0-9]{2}-[0-9]{3}$/,
    },
    HR: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    EE: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    FR: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    DE: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    AT: {
        countryPostCodeLength: 4,
        exampleFormat: '0000',
        regex: /^[0-9]{4}$/,
    },
    GR: {
        countryPostCodeLength: 6,
        exampleFormat: '000 00',
        regex: /^[0-9]{3}\s{1}[0-9]{2}$/,
    },
    IT: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    LV: {
        countryPostCodeLength: 7,
        exampleFormat: 'LV-0000',
        regex: /^LV-[0-9]{4}$/,
    },
    LT: {
        countryPostCodeLength: 8,
        exampleFormat: 'LT-00000',
        regex: /^LT-[0-9]{5}$/,
    },
    NL: {
        countryPostCodeLength: 6,
        exampleFormat: '0000AB',
        regex: /^[0-9]{4}[A-Z]{2}$/,
    },
    PT: {
        countryPostCodeLength: 8,
        exampleFormat: '0000-000',
        regex: /^[0-9]{4}-[0-9]{3}$/,
    },
    SK: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    CH: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
    ES: {
        countryPostCodeLength: 5,
        exampleFormat: '00000',
        regex: /^[0-9]{5}$/,
    },
};

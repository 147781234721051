/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ProductCard from 'Component/ProductCard';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { ProductListPageComponent as SourceComponent } from 'SourceComponent/ProductListPage/ProductListPage.component';
import { noopFn } from 'Util/Common';
import { PRODUCTS_PRELOAD_COUNT } from 'Util/Product';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

import { DEFAULT_PLACEHOLDER_COUNT } from './ProductListPage.config';

/**
 * Placeholder for List of category product
 * @class ProductListPage
 * @namespace Satisfly/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceComponent {
    static defaultProps = {
        numberOfPlaceholders: DEFAULT_PLACEHOLDER_COUNT,
        wrapperRef: noopFn,
        selectedFilters: {},
        pageNumber: undefined,
        items: [],
        mix: {},
        catName: '',
        catId: null,
    };

    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = CategoryPageLayout.GRID,
                } = {},
            },
            catName,
            catId,
        } = this.props;

        return items.map((product, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                      catName={ catName }
                      catId={ catId }
                    />
                );
            }

            return (
                // @ts-ignore
                <AfterPriority fallback={ <div style={ { minHeight: 200 } } /> }>
                    <ProductCard
                      product={ product }
                    // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                      selectedFilters={ selectedFilters }
                      layout={ layout }
                      { ...this.containerProps() }
                      onLoad={ setLoadedFlag }
                      catName={ catName }
                      catId={ catId }
                    />
                </AfterPriority>
            );
        });
    }
}

export default ProductListPageComponent;
